import { Auth, ExpiredAuthSessionError } from '#auth/runtime'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'
import { defu } from 'defu';

// Active schemes
import { OpenIDConnectScheme } from '#auth/runtime'

export default defineNuxtPlugin({
    name: 'nuxt-alt:auth',
    async setup(nuxtApp) {
        // Options
        const options = {
  "globalMiddleware": true,
  "enableMiddleware": true,
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "redirectStrategy": "storage",
  "routerStrategy": "router",
  "watchLoggedIn": true,
  "redirect": {
    "login": "/login",
    "logout": "/",
    "home": "/",
    "callback": "/auth"
  },
  "pinia": {
    "namespace": "auth"
  },
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/"
    }
  },
  "localStorage": {
    "prefix": "auth."
  },
  "sessionStorage": {
    "prefix": "auth."
  },
  "defaultStrategy": "aad"
}

        // Create a new Auth instance
        const auth = new Auth(nuxtApp, options)

        // Register strategies
        auth.registerStrategy('aad', new OpenIDConnectScheme(auth, defu(useRuntimeConfig()?.public?.auth?.strategies?.['aad'], {
  "endpoints": {
    "authorization": "__PLACEHOLDER_OAUTH_AUTH_ENDPOINT__",
    "token": "__PLACEHOLDER_OAUTH_TOKEN_ENDPOINT__",
    "logout": "__PLACEHOLDER_OAUTH_LOGOUT_ENDPOINT__",
    "configuration": "__PLACEHOLDER_OAUTH_CONFIG_ENDPOINT__"
  },
  "user": {
    "property": false
  },
  "token": {
    "property": "id_token",
    "type": "Bearer",
    "maxAge": 1800
  },
  "refreshToken": {
    "property": "refresh_token",
    "data": "refresh_token",
    "maxAge": 3600
  },
  "responseType": "code",
  "grantType": "authorization_code",
  "accessType": "offline",
  "redirectUri": "__PLACEHOLDER_OAUTH_REDIRECT_URI__",
  "logoutRedirectUri": "",
  "clientId": "__PLACEHOLDER_OAUTH_CLIENT_ID__",
  "scope": [
    "openid",
    "profile",
    "email",
    "offline_access"
  ],
  "state": "__PLACEHOLDER_OAUTH_SECRET_STATE__",
  "codeChallengeMethod": "S256",
  "name": "aad"
})))

        nuxtApp.provide('auth', auth)

        return auth.init()
        .catch(error => {
            if (process.client) {
                // Don't console log expired auth session errors. This error is common, and expected to happen.
                // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
                // token. We don't want to log this as an error.
                if (error instanceof ExpiredAuthSessionError) {
                    return
                }

                console.error('[ERROR] [AUTH]', error)
            }
        })
    }
})