

export const isDev = false
export function vuetifyConfiguration() {
  const options = {"blueprint":{"defaults":{"VAppBar":{"flat":true},"VAutocomplete":{"variant":"filled"},"VBanner":{"color":"primary"},"VBottomSheet":{"contentClass":"rounded-t-xl overflow-hidden"},"VBtn":{"color":"primary","rounded":"xl"},"VBtnGroup":{"rounded":"xl","VBtn":{"rounded":null}},"VCard":{"rounded":"lg"},"VCheckbox":{"color":"secondary","inset":true},"VChip":{"rounded":"sm"},"VCombobox":{"variant":"filled"},"VNavigationDrawer":{},"VSelect":{"variant":"filled"},"VSlider":{"color":"primary"},"VTabs":{"color":"primary"},"VTextarea":{"variant":"filled"},"VTextField":{"variant":"filled"},"VToolbar":{"VBtn":{"color":null}}},"theme":{"themes":{"light":{"colors":{"primary":"#6750a4","secondary":"#b4b0bb","tertiary":"#7d5260","error":"#b3261e","surface":"#fffbfe"}}}}},"theme":{"variations":{"colors":["primary","secondary"],"lighten":5,"darken":4},"themes":{"light":{"dark":false,"colors":{"primary":"#0058a3","secondary":"#ffdb00","error":"#ff4343","background":"#FFFFFF","surface":"#FFFFFF"}}}},"defaults":{"VChip":{"style":"border-radius: 8px !important;"}}}
  
  
  
  
  return options
}

