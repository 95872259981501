import type { JumpChange } from "~/types/jumpChange"

export enum NotificationType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

export type Notification = {
  message: string
  type: NotificationType
  jumpToChange?: JumpChange
}
