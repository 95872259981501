<template>
  <div class="mt-10">
    <v-row v-if="error.statusCode === 403">
      <v-col cols="12" class="py-2">
        <v-row justify="center" align="ceniter">
          <v-flex shrink>
            <span class="text-error text-h1">
              {{ errorStatus }}
            </span>
          </v-flex>
          <v-flex shrink ml-6>
            <p class="text-h5 font-weight-bold ma-0">
              {{ errorStatusText }}
              <v-icon size="28" color="black">mdi-emoticon-sad-outline</v-icon>
            </p>
            <p class="text-h6 font-weight-light ma-0 text-error">User does not have access to FATIMA</p>
          </v-flex>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else-if="error.statusCode === 401 || !loggedIn" justify="center" align="center">
      <span class="text-h5">
        Logging in ...
        <v-icon size="28" color="black">mdi-emoticon-happy-outline</v-icon>
      </span>
    </v-row>
    <v-row v-else>
      <v-col cols="12" class="py-2">
        <v-row justify="center" align="center">
          <v-flex shrink>
            <span class="text-error text-h1">
              {{ errorStatus }}
            </span>
          </v-flex>
          <v-flex shrink ml-6>
            <p class="text-h5 font-weight-bold ma-0">
              {{ errorStatusText }}
              <v-icon size="28" color="black">mdi-emoticon-sad-outline</v-icon>
            </p>
            <p class="text-h6 font-weight-light ma-0 text-error">{{ errorMessage }}</p>
          </v-flex>
        </v-row>
      </v-col>
      <v-row justify="center" class="mt-10">
        <v-btn class="bg-primary" @click="goBack">Back</v-btn>
      </v-row>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { useRootStore } from "~/stores/store"
import { useAuth } from "~/.nuxt/imports"

const auth = useAuth()
const store = useRootStore()

const props = defineProps<{
  error: any
}>()

onMounted(() => {
  if (props.error.statusCode === 403 || props.error.statusCode === 401) {
    store.authenticated = false
  }
})

const loggedIn = computed(() => {
  return auth.loggedIn
})

const errorStatus = computed(() => props.error.response?.status || props.error.statusCode)

const errorStatusText = computed(() => {
  if (props.error.isAxiosError) {
    return props.error.response?.statusText
  } else {
    return "An error occurred"
  }
})

const errorMessage = computed(() => {
  if (props.error.isAxiosError) {
    return props.error.response?.data?.message || props.error.response?.data || props.error.message
  } else {
    return props.error.message
  }
})

const goBack = () => {
  if (props.error.statusCode === 404) {
    navigateTo("/")
  } else {
    window.location.reload()
  }
}
</script>
