import { defineStore } from "pinia"
import type { Ref } from "vue"
import { ref } from "vue"
import type { Notification } from "~/types/notification"

export const useNotificationStore = defineStore("notification", () => {
  const notification: Ref<Notification | null> = ref(null)

  return { notification }
})
