import revive_payload_client_Dm6BYUF0h6 from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@3.29.5_sass@1.80.3_typescript@5.2.2_vite@5.4.9/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_nhdpnA670Q from "/opt/app-root/src/node_modules/.pnpm/vuetify-nuxt-module@0.14.1_rollup@3.29.5_typescript@5.2.2_vite@5.4.9_vue@3.5.12/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_fTwA4TlsU9 from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@3.29.5_sass@1.80.3_typescript@5.2.2_vite@5.4.9/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_PcrV1pSa6s from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@3.29.5_sass@1.80.3_typescript@5.2.2_vite@5.4.9/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_YFZcn2p1Wr from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@3.29.5_sass@1.80.3_typescript@5.2.2_vite@5.4.9/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_OWoYuo0g7B from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@3.29.5_sass@1.80.3_typescript@5.2.2_vite@5.4.9/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_PyuwSah1dD from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@3.29.5_sass@1.80.3_typescript@5.2.2_vite@5.4.9/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UQ3Tv641aA from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@3.29.5_sass@1.80.3_typescript@5.2.2_vite@5.4.9/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_xyQqIIOGEo from "/opt/app-root/src/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@3.29.5_typescript@5.2.2_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/app-root/src/.nuxt/components.plugin.mjs";
import prefetch_client_D54Vf9JJ6T from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@3.29.5_sass@1.80.3_typescript@5.2.2_vite@5.4.9/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import http_plugin_nuxt_W6U7lXuHEt from "/opt/app-root/src/node_modules/.pnpm/@nuxt-alt+http@1.7.10_nuxi@3.14.0_rollup@3.29.5_sass@1.80.3_typescript@5.2.2/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import auth_plugin_6DDHMgc34l from "/opt/app-root/src/.nuxt/auth.plugin.mjs";
import vuetify_no_client_hints_dYqtEhhGmx from "/opt/app-root/src/node_modules/.pnpm/vuetify-nuxt-module@0.14.1_rollup@3.29.5_typescript@5.2.2_vite@5.4.9_vue@3.5.12/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import auth_vT9JWWT9pN from "/opt/app-root/src/plugins/auth.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/opt/app-root/src/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_Dm6BYUF0h6,
  vuetify_icons_nhdpnA670Q,
  unhead_fTwA4TlsU9,
  router_PcrV1pSa6s,
  payload_client_YFZcn2p1Wr,
  navigation_repaint_client_OWoYuo0g7B,
  check_outdated_build_client_PyuwSah1dD,
  chunk_reload_client_UQ3Tv641aA,
  plugin_vue3_xyQqIIOGEo,
  components_plugin_KR1HBZs4kY,
  prefetch_client_D54Vf9JJ6T,
  http_plugin_nuxt_W6U7lXuHEt,
  auth_plugin_6DDHMgc34l,
  vuetify_no_client_hints_dYqtEhhGmx,
  auth_vT9JWWT9pN,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]