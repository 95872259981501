import { defineStore } from "pinia"
import type { Ref } from "vue"
import { ref } from "vue"
import type { Item } from "~/types/item"

export const useRootStore = defineStore("store", () => {
  const searchComment: Ref<string | null> = ref(null)
  const validityDate: Ref<Date> = ref(new Date())
  const itemForHistory: Ref<Item | null> = ref(null)
  const itemGroupingSuggestion: Ref<Item[]> = ref([])
  const numberItemsWithoutMapping = ref(0)
  const authenticated = ref(true)
  const groupingItemsMode = ref(false)

  const enableGroupingItemsMode = () => {
    groupingItemsMode.value = true
  }

  const disableEditingIncompleteItemsMode = () => {
    groupingItemsMode.value = false
  }

  return {
    searchComment,
    validityDate,
    itemForHistory,
    itemGroupingSuggestion,
    numberItemsWithoutMapping,
    authenticated,
    groupingItemsMode,
    enableGroupingItemsMode,
    disableEditingIncompleteItemsMode,
  }
})
