import type { FetchInstance } from "@refactorjs/ofetch"
import type { Auth } from "#auth/runtime"
import { useNotificationStore } from "~/stores/notification"
import { NotificationType } from "~/types/notification"

export default function ({ $auth, $http }: { $auth: Auth; $http: FetchInstance }) {
  const notificationStore = useNotificationStore()

  $http.onRequest(() => {
    if (!$auth.loggedIn) {
      setTimeout(() => {
        $auth.loginWith("aad")
      }, 100)
    }
  })

  $http.onResponseError(
    (error: { response: { status: number; data: { status: number; path: string; message: any } }; message: any }) => {
      if (!$auth.loggedIn) {
        setTimeout(() => {
          $auth.loginWith("aad")
        }, 100)
      } else if (error.response?.status !== 401) {
        notificationStore.notification = {
          message: "Something went wrong! " + error.message,
          type: NotificationType.ERROR,
        }
      }
    }
  )
}
